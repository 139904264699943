define(['app', 'productAddToBasket', '$window', 'siteObj'], function(app, productAddToBasket, $window, siteObj) {

  var productAddToBasketButton = function() {
    var component = this;

    var _config = {
      selectors: {
        component: '[data-component=productAddToBasketButton]',
        productAddToBasketButton: '[data-product-add-to-basket-button]',
        isSticky: '[data-issticky]',
        productAddToBasket: '.productAddToBasket',
        personalisableProduct: '[data-component=personalisationSelect]',
        withEngravingTab: '.personalisation_withEngraving_tab',
        isCTAStickyBottom: '[cta-sticky-bottom]',
        displayStickyBottom: '.cta-sticky-bottom',
        isCTAStickyTop: '[cta-sticky-top]',
        displayStickyTop: '.cta-sticky-top',
        isResponsiveUsp: '.responsiveUsp',
      },
      attr: {
        hasDigitalOrPhysicalOption: 'data-has-digital-or-physical-sku',
        hasAlternativeWording: 'data-has-alternative-wording-enabled',
        disabled: 'disabled',
        purchaseMethod: 'data-basket-purchase-method',
        personalisationSelectedTab: 'data-selected',
        checkCTAattrBottom: 'cta-sticky-bottom',
        checkCTAattrTop: 'cta-sticky-top',
        checkStickyAttr: 'data-issticky',
      },
      classes: {
        stickClass: 'productAddToBasket-sticky',
        hideSticky: 'hide',
        responsiveUspMargin: 'cta-sticky-top',
      },
      subscribeChannels: {
        addToBasketButton: 'productAddToBasketButton/newButton'
      }
    };

    var _init = function(element) {
      component.element = element;
      app.subscribe(component.config.subscribeChannels.addToBasketButton, component.updateButton);
      component.addEventListeners();

      return component;
    };

    const _addEventListeners = function() {
      if (component.element && siteObj.type === "product" && (component.element.querySelector(component.config.selectors.isSticky)|| component.element.querySelector(component.config.selectors.isCTAStickyTop) || component.element.querySelector(component.config.selectors.isCTAStickyBottom))) {
        $window.addEventListener('scroll', component.callStickEvent);
        component.callStickEvent();
      }
    };

    const _callStickEvent = function() {
      const wrapper = component.getStickyBtnWrapper();
      component.stickEvent(wrapper);
    };

    const _getStickyBtnWrapper = function() {
      const stickyBtn = document.querySelector(component.config.selectors.isSticky);
      const stickyCTABtn = document.querySelectorAll(`${component.config.selectors.productAddToBasket}`)[1];

      if (stickyBtn) {
        return stickyBtn.parentElement;
      }

      if(stickyCTABtn) {
        return stickyCTABtn.parentElement;
      }

      return null;
    };

    const _stickEvent = function(stickyBtnWrapper) {
      if(stickyBtnWrapper && component.element.querySelector(component.config.selectors.isCTAStickyBottom)) {
        const windowHeight = $window.innerHeight;
        const stickPositionTop = stickyBtnWrapper.getBoundingClientRect().top;
        const stickPositionBottom = stickyBtnWrapper.getBoundingClientRect().bottom;
        //Outside of Viewport
        if ((stickPositionTop < 0 || stickPositionTop > windowHeight)) {
          document.querySelector(component.config.selectors.displayStickyBottom).classList.remove(component.config.classes.hideSticky);
        }
        //Inside of viewport
        else if(stickPositionTop > 0 && stickPositionBottom <= windowHeight) {
          document.querySelector(component.config.selectors.displayStickyBottom).classList.add(component.config.classes.hideSticky);
        }
      }

      else if(stickyBtnWrapper && component.element.querySelector(component.config.selectors.isCTAStickyTop)) {
        const windowHeight = $window.innerHeight;
        const stickPositionTop = stickyBtnWrapper.getBoundingClientRect().top;
        const stickPositionBottom = stickyBtnWrapper.getBoundingClientRect().bottom;
        //Outside of Viewport
        if ((stickPositionTop < 0 || stickPositionTop > windowHeight)) {
          document.querySelector(component.config.selectors.displayStickyTop).classList.remove(component.config.classes.hideSticky);
          document.querySelector(component.config.selectors.isResponsiveUsp).classList.add(component.config.classes.responsiveUspMargin);
        }
        //Inside of viewport
        else if(stickPositionTop > 0 && stickPositionBottom <= windowHeight) {
          document.querySelector(component.config.selectors.displayStickyTop).classList.add(component.config.classes.hideSticky);
          document.querySelector(component.config.selectors.isResponsiveUsp).classList.remove(component.config.classes.responsiveUspMargin);
        }
      }

      else if (stickyBtnWrapper && component.element.querySelector(component.config.selectors.isSticky)) {
        const windowHeight = $window.innerHeight;
        const stickPosition = stickyBtnWrapper.getBoundingClientRect().bottom;
        if (stickPosition < windowHeight && !stickyBtnWrapper.classList.contains(component.config.classes.stickClass)) {
          stickyBtnWrapper.classList.add(component.config.classes.stickClass);
        }

        const unstickPosition = stickyBtnWrapper.parentElement.getBoundingClientRect().top + stickyBtnWrapper.clientHeight;
        if (unstickPosition >= windowHeight && stickyBtnWrapper.classList.contains(component.config.classes.stickClass)) {
          stickyBtnWrapper.classList.remove(component.config.classes.stickClass);
        }
      }
    };

    /*
    send ga4 event to show new item has been viewed
    */
    var _ga4ViewItem = function (newButtonData) {
      const parser = new DOMParser();
      const newButtonElement = parser.parseFromString(newButtonData, 'text/html');
      const newButtonElementData = newButtonElement.querySelector('[data-product-id]').dataset;

      const ga4EventData = {
        'event': 'ecom_event',
        'event_name': 'view_item',
        'currency': siteObj.currencyType,
        'items': 
          [{
            'item_id': newButtonElementData.productId,
            'item_name': newButtonElementData.productName,
            'price': newButtonElementData.productPrice.replace(/[^0-9.]/g,''),
            'brand': newButtonElementData.productBrand,
            'category': newButtonElementData.productCategory
          }]
      }

      app.publish('record/general', ga4EventData);
    }

    var _newButtonReceived = function(newButtonData) {
      component.ga4ViewItem(newButtonData);
      var newContainer = document.createElement('div');
      newContainer.innerHTML = newButtonData;
      var newElem = newContainer.querySelector(component.config.selectors.component);
      var hasDigitalOrPhysicalOption = newElem.hasAttribute(component.config.attr.hasDigitalOrPhysicalOption);

      if (hasDigitalOrPhysicalOption) {
        var parentNode = component.element.parentNode;

        parentNode.replaceChild(newElem, component.element);
        component.element = newElem;
      }

      var newButton = newElem.querySelector(component.config.selectors.productAddToBasketButton);

      var oldButton = component.element.querySelector(component.config.selectors.productAddToBasketButton);

      if(siteObj.config.enableStickyTopCTAAddToBasket === 'true' || siteObj.config.enableStickyBottomCTAAddToBasket === 'true') {
        if(oldButton.firstElementChild.hasAttribute('data-component-tracked-clicked')) {
          newButton.firstElementChild.setAttribute('data-component-tracked-clicked', '');
          const oldButtonTrackAttribute = oldButton.firstElementChild.getAttribute('data-context');
          newButton.firstElementChild.setAttribute('data-context', oldButtonTrackAttribute);
        }
      }

      if (oldButton.firstElementChild != null) {
        if(oldButton.firstElementChild.hasAttribute(component.config.attr.checkCTAattrBottom)){
          newButton.firstElementChild.setAttribute(component.config.attr.checkCTAattrBottom,'');
        }

        if(oldButton.firstElementChild.hasAttribute(component.config.attr.checkCTAattrTop)){
          newButton.firstElementChild.setAttribute(component.config.attr.checkCTAattrTop,'');
        }

        if(oldButton.firstElementChild.hasAttribute(component.config.attr.checkStickyAttr)) {
          newButton.firstElementChild.setAttribute(component.config.attr.checkStickyAttr,'');
        }
      }

      if (newButton) {
        component.element.removeChild(oldButton);
        component.element.appendChild(newButton);
        var newButtonElem = newButton.querySelector('[data-component=productAddToBasket]');
        if (newButtonElem) {
          var addToBasketSpan = newContainer.querySelector('[data-linked-product]');
          if (addToBasketSpan) {
            var productId = addToBasketSpan.getAttribute('data-product-id');
          }
          newButtonElem.componentObject = new productAddToBasket().init(newButtonElem, productId);
        }
      }

      if (!newElem.hasAttribute('data-linked-product')) {
        const hasQuantity = newElem.getAttribute('data-has-quantity-input') === 'true';
        app.publish('productQuantity/updateVisbilty', hasQuantity);
      }

      if (newButtonElem) {
        app.publish('subscribeAndSaveProductInBasket/showMessage', newButtonElem.getAttribute(component.config.attr.purchaseMethod));
      }

      component.callStickEvent();
    };

    var _updateButton = function(productId, disable) {
      if (disable) {
        let btn = component.element.querySelector(component.config.selectors.productAddToBasket);
        btn && btn.setAttribute(component.config.attr.disabled, 'true');
      } else {
        let buttonURL = '/' + productId + '.addtobasketbutton';
        const queryParams = [];
        if (component.element) {
          component.element.hasAttribute(component.config.attr.hasAlternativeWording) && queryParams.push('useAlternativeCTAWording=true');
        }

        if (component.hasProductPersonalisation()) {
          const withEngravingTab = document.querySelector(component.config.selectors.withEngravingTab);
          if (withEngravingTab) {
            const isTabSelected = withEngravingTab.getAttribute(component.config.attr.personalisationSelectedTab);
            const selectedTabWithEngraving = isTabSelected === 'true';
            queryParams.push(`usePersonalisationPricing=${selectedTabWithEngraving}`);
          }
        }

        if (new URLSearchParams(window.location.search).get('addToBasketButtonWithPrice') === 'true') {
          queryParams.push('addToBasketButtonWithPrice=true');
        }

        const queryString = queryParams.join('&');
        if (queryString) {
          buttonURL += '?' + queryString;
        }

        app.ajax.get({
          url: buttonURL,
          success: component.newButtonReceived,
          error: component.newButtonErrorHandler
        });
      }
    };

    var _newButtonErrorHandler = function() {
      console.error(
        'ERROR: Could not retrieve child product add to basket button');
    };

    const _hasProductPersonalisation = () => {
      return !!document.querySelector(component.config.selectors.personalisableProduct);
    }

    component.init = _init;
    component.config = _config;
    component.addEventListeners = _addEventListeners;
    component.stickEvent = _stickEvent;
    component.newButtonReceived = _newButtonReceived;
    component.updateButton = _updateButton;
    component.newButtonErrorHandler = _newButtonErrorHandler;
    component.getStickyBtnWrapper = _getStickyBtnWrapper;
    component.callStickEvent = _callStickEvent;
    component.hasProductPersonalisation = _hasProductPersonalisation;
    component.ga4ViewItem = _ga4ViewItem;

    return component;
  };

  return productAddToBasketButton;
});
